import React, { useEffect, useRef, useState } from 'react'
import Head from '../layout/head/Head'
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from '../components/Block'
import Icon from '../components/Icon'
import { Button, ModalFooter, Modal, ModalBody, Table } from 'reactstrap'
import axios from 'axios'
import { get_student, get_verified } from '../utils/constants'
import { Link } from 'react-router-dom'
// import CertificateGenerator from '../certificate-generator/CertificateGenerator'
import { PreviewCard } from '../components/Preview'
import SITHLOGOWHITE from "../images/SITH_LOGO_WHITE.png";
import SITHLOGOBLACK from "../images/SITH_LOGO_BLACK.png";
import AuthFooter from '../Auth/AuthFooter'
import { ToastContainer, toast } from 'react-toastify'
// import CertificateTemplate from '../images/Website Certificate SITH.jpg'

function VerfiyPage() {
    const [data, setData] = useState([]);
    const [certificate_number, setCertificate_number] = useState("");
    const [displayTable, setDisplayTable] = useState(false);
    const getDetails = () => {
        if (certificate_number.length > 0) {
            axios.get(get_verified + certificate_number)
                .then(res => {
                    const { data, message, success } = res.data
                    if (success) {
                        if (!data) {
                            toast.error(message);
                            setDisplayTable(false);
                        }
                        else {
                            const { certificate_number, name, course } = data;
                            setData({
                                certificate_number: certificate_number,
                                name: name,
                                course: course,
                            })
                            setDisplayTable(true);
                            toast.success(message);
                        }
                    }
                    else {
                        toast.error("Something went wrong");
                        setDisplayTable(false);
                    }
                })
                .catch(err => {
                    toast.error(err.response.data.message);
                    setDisplayTable(false);
                });

        } else {
            toast.error("Please enter certificate number");
        }
    };
    return (
        <React.Fragment>
            <ToastContainer position="bottom-right" hideProgressBar="true" />
            <Head title="Certificate" />
            <Block className="nk-block-middle nk-auth-body mt-5 pt-5 wide-md">
                <div className="brand-logo text-center">
                    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
                        <img className="logo-light logo-img logo-img-lg" src={SITHLOGOWHITE} alt="logo" />
                        <img className="logo-dark logo-img logo-img-lg" src={SITHLOGOBLACK} alt="logo-dark" />
                    </Link>
                </div>
                <PreviewCard className="card-bordered mt-5 " bodyClass="card-inner-lg">
                    <BlockHead>
                        <BlockContent>
                            <BlockTitle tag="h5">Verify Certificate</BlockTitle>
                            <BlockDes>
                                <p>Please enter the certificate number provided by the institute.</p>
                            </BlockDes>
                        </BlockContent>
                    </BlockHead>
                    <form onSubmit={(e) => {e.preventDefault(); getDetails();}}>
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="default-01">
                                    Certificate Number
                                </label>
                            </div>
                            <div className="form-control-wrap">
                                <div className="form-icon form-icon-right pt-1">
                                    <Icon name="search"></Icon>
                                </div>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="default-01"
                                    placeholder="Please enter your certificate number"
                                    onChange={(e) => setCertificate_number(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <Button color="primary" size="lg" className="btn-block" type='submit'>
                                Verify Certificate
                            </Button>
                        </div>
                    </form>
                    <Table striped bordered hover responsive className={displayTable ? "mt-5 d-block" : "d-none"}>

                        <thead>
                            <tr>
                                <th style={{ width: "10%" }}>Certificate Number</th>
                                <th style={{ width: "10%" }}>Name</th>
                                <th style={{ width: "10%" }}>Course</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data.certificate_number}</td>
                                <td>{data.name}</td>
                                <td>{data.course}</td>
                            </tr>
                        </tbody>
                    </Table>
                </PreviewCard>
            </Block>
            <AuthFooter />
        </React.Fragment >
    )
}

export default VerfiyPage