import React from "react";
import { Row, Col } from "../components/Component";
import { Link } from "react-router-dom";

const AuthFooter = () => {
  return (
    <>
      <div className="nk-footer nk-auth-footer-full mb-0">
        <div className="container-fluid wide-lg">
          <Row className="g-3">
            <Col lg={6} className="order-lg-last">
              <ul className="nav nav-sm justify-content-center justify-content-lg-end">
                <li className="nav-item">
                  <Link className="nav-link" target="_blank" to={`https://sithcomputers.com/all-courses/`}>
                    Courses
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" target="_blank" to={`https://sithcomputers.com/blogs/`}>
                    Blogs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" target="_blank" to={`https://sithcomputers.com/contact-us/`}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </Col>
            <Col lg="6">
              <div className="nk-block-content text-center text-lg-start">
                <p className="text-soft">
                  <Link className="nav-link" target="_blank" to={`https://sithcomputers.com/`}>
                    &copy; 2024 SITH - Solutions for IT. All Rights Reserved
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div >
    </>
  );
};
export default AuthFooter;
