import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Row,
    Col,
    PreviewCard,
    LineChartExample,
    BlockBetween,
} from "../../../components/Component";
import { get_report } from "../../../utils/constants";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
function Report() {
    const [Data, setData] = useState([]);
    const [branch, setBranch] = useState([]);
    const filledLineChart = {
        labels: Data.courses,
        dataUnit: "data",
        datasets: [
            {
                label: "Total Received",
                lineTension: 0.4,
                borderColor: "#9d72ff",
                backgroundColor: "rgba(157, 114, 255, 0.15)",
                pointBorderWidth: 3,
                pointBackgroundColor: "white",
                pointRadius: 5,
                fill: true,
                data: Data.studentCounts,
            },
        ],
    };


    useEffect(() => {
        axios.get(get_report)
            .then(res => {
                setData(res.data.data);
                setBranch(res.data.data.branchwiseTotalCount);
            })
            .catch(err => {
                toast.error(err.response.data.message);

            })
    }, [])
    return (

        <React.Fragment>
            <Head title="Certificate Report"></Head>
            <Content>
                <BlockHead size="md">
                    <div className="mt-3">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle page>Certificate Report</BlockTitle>
                            </BlockHeadContent>
                        </BlockBetween>
                    </div>
                </BlockHead>
                <Block>
                    <Row className="g-gs">
                        <Col md={12} >
                            <PreviewCard>
                                <div className="analytic-data-group analytic-ov-group g-3">
                                    <div className="analytic-data analytic-ov-data">
                                        <div className="title">Total Students</div>
                                        <div className="amount">{Data.totalStudentCount}</div>
                                    </div>
                                    {Array.isArray(branch) && branch.map((item) => (
                                        <div className="analytic-data analytic-ov-data" key={item.label}>
                                            <div className="title">{item.label}</div>
                                            <div className="amount">{item.value}</div>
                                        </div>
                                    ))}
                                </div><br />
                                <div className="nk-ck">
                                    <LineChartExample legend={false} data={filledLineChart} />
                                </div>
                            </PreviewCard>
                        </Col>
                    </Row>
                </Block>
            </Content>
            <ToastContainer position="bottom-right" hideProgressBar="true" />

        </React.Fragment >
    )
}

export default Report;