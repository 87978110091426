import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Card } from "reactstrap";
import {
	Button,
	Block,
	BlockBetween,
	BlockDes,
	BlockHead,
	BlockHeadContent,
	BlockTitle,
	Icon,
	PaginationComponent,
	RSelect,
} from "../../../components/Component";
import { add_issue_record, delete_issue_record, get_issue_list, get_student_datalist } from "../../../utils/constants";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const IssueDate = () => {
	const [data, setData] = useState([]);
	const [options, setOptions] = useState([]);
	const [sm, updateSm] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');
	//   useEffect(() => {
	//     axios.get(get_issue_list)
	//       .then(res => {
	//         setData(res.data.data)
	//       })
	//       .catch(err => {
	//         toast.error(err.response.data.message);
	//       })

	//   }, [data]);
	const [dataVersion, setDataVersion] = useState(0); // Track changes in data

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(get_issue_list);
				setData(response.data.data);
			} catch (err) {
				toast.error(err.response.data.message);
			}
		};

		fetchData();
	}, [dataVersion]); // Re-run effect only when dataVersion changes

	// Function to trigger re-fetching data
	const reloadData = () => {
		setTimeout(() => {
			setDataVersion(prev => prev + 1); // Increment dataVersion to trigger effect
		},300)
	};

	useEffect(() => {
		const fetchData = async () => {
			const result = await axios(
				get_student_datalist
			).catch((err) => {
				toast.error(err.response.data.message);
			})
			const options = result.data.data.map((user) => {
				return { value: user.value, label: user.label };
			});
			setOptions(options);
		};

		fetchData();
	}, []);


	const [onSearch, setonSearch] = useState(true);
	const [onSearchText, setSearchText] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage] = useState(50);

	// Sorting data
	const indexOfLastItem = currentPage * itemPerPage;
	const indexOfFirstItem = indexOfLastItem - itemPerPage;

	// Change Page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const handleSelectChange = (selectedOption) => {
		setSelectedOption(selectedOption);
	};
	const filteredData = data.filter(
		(result) => result.first_name.toLowerCase().includes(onSearchText.toLowerCase()) || result.middle_name.toLowerCase().includes(onSearchText.toLowerCase()) || result.last_name.toLowerCase().includes(onSearchText.toLowerCase())
	);

	const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
	// function to toggle the search option
	const toggle = () => setonSearch(!onSearch);
	const addStudent = () => {
		if (!selectedOption) {
			toast.error("Please select a student");
		}
		else {
			selectedOption.map((item) => {
				axios.post(add_issue_record + item.value)
					.then(res => {
						if (res.data.success == "true") {
							toast.success(res.data.message);
						}
						else {
							toast.error(res.data.message);
						}
					})
					.catch(err => {
						toast.error(err.response.data.message);
					})
			})
			setSelectedOption('');
		}
		reloadData();
	}
	const deleteRecord = (certificate_number) => {
		axios.delete(delete_issue_record + certificate_number)
			.then(res => {
				toast.success(res.data.message);
			})
			.catch(err => {
				toast.error(err.response.data.message);
			})
		reloadData();
	};



	return (
		<React.Fragment>
			<Head title="Issued Certificate"></Head>
			<Content>
				<BlockHead size="lg">
					<div className="mt-3">
						<BlockBetween>
							<BlockHeadContent>
								<BlockTitle page>Issued Certificate</BlockTitle>
								<BlockDes className="text-soft">
									<p>Please select student name to issue his/her certificate.</p>
								</BlockDes>
							</BlockHeadContent>
							<BlockHeadContent>
								<div className="toggle-wrap nk-block-tools-toggle">
									<a
										href="#more"
										className="btn btn-icon btn-trigger toggle-expand me-n1"
										onClick={(ev) => {
											ev.preventDefault();
											updateSm(!sm);
										}}
									>
										<Icon name="more-v"></Icon>
									</a>
									<div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
										<ul className={sm ? "nk-block-tools g-3 d-flex flex-column" : "nk-block-tools g-3"}>
											<li>
												<div className="form-control-wrap">
													<div className="form-icon form-icon-right">
														<Icon name="search"></Icon>
													</div>
													<RSelect
														isMulti
														className="w-300px w-min-200px"
														value={selectedOption}
														options={options} onChange={handleSelectChange}
													/>
												</div>
											</li>
											<li className="form-control-wrap">
												<Button
													className="toggle d-md-inline-flex"
													color="primary"
													onClick={addStudent}
												>
													<Icon name="plus"></Icon>
													<span>Add</span>
												</Button>
											</li>
										</ul>
									</div>
								</div>
							</BlockHeadContent>
						</BlockBetween>
					</div>
				</BlockHead>
				<Block>
					<Card className="card">
						<div className="card-inner-group">
							<div className="card-inner">
								<div className="card-title-group">
									<div className="card-title">
										<h5 className="title">Issued Students</h5>
									</div>
									<div className="card-tools me-n1">
										<ul className="btn-toolbar">
											<li>
												<Button onClick={toggle} className="btn-icon search-toggle toggle-search" >
													<Icon name="search"></Icon>
												</Button>
											</li>
										</ul>
									</div>
									<div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
										<div className="search-content">
											<Button
												className="search-back btn-icon toggle-search"
												onClick={() => {
													setSearchText("");
													toggle();
												}}
											>
												<Icon name="arrow-left"></Icon>
											</Button>
											<input
												type="text"
												className="form-control border-transparent form-focus-none"
												placeholder="Search Student Name"
												value={onSearchText}
												onChange={(e) => { setSearchText(e.target.value); reloadData(); }}
											/>
											<Button className="search-submit btn-icon">
												<Icon name="search"></Icon>
											</Button>
										</div>
									</div>
								</div>
							</div>
							<div className="card-inner p-0 table-responsive">
								<table className="table table-orders ">
									<thead className="tb-odr-head">
										<tr className="tb-odr-item">
											<th className="tb-odr-info">
												<span className="tb-odr-id  d-md-inline-block">Certificate Number</span>
											</th>
											<th className="tb-odr-info">
												<span className="tb-odr-name d-md-inline-block">Name</span>
											</th>
											<th className="tb-odr-info">
												<span className="tb-odr-course d-md-inline-block">Course</span>
											</th>
											<th className="tb-odr-info">
												<span className="tb-odr-date d-md-inline-block">Issue Date</span>
											</th>
											<th className="tb-odr-info">Delete</th>
										</tr>
									</thead>
									<tbody className="tb-odr-body">
										{currentItems.length > 0
											? currentItems.map((item) => {
												return (
													<tr className="tb-odr-item" key={item.certificate_number}>
														<td className="tb-odr-info">
															<span className="tb-odr-id">
																{item.certificate_number}
															</span>
														</td>
														<td className="tb-odr-info">
															<span className="tb-odr-date">{item.first_name + ' ' + item.middle_name + ' ' + item.last_name}</span>
														</td>
														<td className="tb-odr-info">
															<span className="tb-odr-date">
																{item.course}
															</span>
														</td>
														<td className="tb-odr-info">
															<span className="tb-odr-date">
																{new Date(item.issuedDate).toISOString().slice(0, 10)}
															</span>
														</td>
														<td className="tb-odr-info">
															<div className="tb-odr-btns ">
																<Button color="danger" size="sm" className="btn btn-dim" onClick={(e) => { e.preventDefault(); deleteRecord(item.certificate_number) }}>
																	<Icon name="trash-fill"></Icon>
																</Button>
															</div>
														</td>
													</tr>
												);
											})
											: null}
									</tbody>
								</table>
							</div>
							<div className="card-inner">
								{filteredData.length > 0 ? (
									<PaginationComponent
										noDown
										itemPerPage={itemPerPage}
										totalItems={filteredData.length}
										paginate={paginate}
										currentPage={currentPage}
									/>
								) : (
									<div className="text-center">
										<span className="text-silent">No data found</span>
									</div>
								)}
							</div>
						</div>
					</Card>
				</Block>
			</Content >
			<ToastContainer position="bottom-right" hideProgressBar="true" />

		</React.Fragment >
	);
};
export default IssueDate;

