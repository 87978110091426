import React, { useState, useEffect, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import Form from 'react-bootstrap/Form';
import {
	Block,
	BlockHead,
	BlockTitle,
	BlockBetween,
	BlockHeadContent,
	BlockDes,
	Icon,
	Row,
	Col,
	Button,
	DataTableHead,
	DataTableRow,
	DataTableItem,
	PaginationComponent,
	PreviewAltCard,
} from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, Input, ModalFooter } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useForm } from "react-hook-form";
import { Modal, ModalBody } from "reactstrap";
import { create_student, delete_multiple_student, delete_student, get_branch, get_course, get_specific_student_data, get_student, get_student_list, update_student, update_yes_no } from "../../../utils/constants";
import axios from "axios";
import CertificateGenerator from "../../../certificate-generator/CertificateGenerator";
import { ToastContainer, toast } from "react-toastify";
import CertificateTemplate from '../../../images/Website Certificate SITH.jpg'
import { DataTableBody } from "../../../components/DataTable";
import { useTheme } from "../../../layout/provider/Theme";
import JSZip from "jszip";

const AddCertificate = () => {
	const theme = useTheme();
	const [bulkData, setBulkData] = useState([]);
	const [data, setData] = useState([]);
	const [sm, updateSm] = useState(false);
	const [imageSrc] = useState(CertificateTemplate);
	const canvasRef = useRef(null);
	//   useEffect(() => {
	//     axios.get(get_student_list)
	//       .then(res => {
	//         setData(res.data.data)
	//       })
	//       .catch(err => {
	//         toast.error(err.response.data.message);
	//       })
	//     axios.get(get_branch)
	//       .then(res => {
	//         setBranchOptions(res.data.data);
	//       })
	//       .catch(err => {
	//         toast.error(err.response.data.message);
	//       })
	//     axios.get(get_course)
	//       .then(res => {
	//         setCourseOptions(res.data.data)
	//       })
	//       .catch(err => {
	//         toast.error(err.response.data.message);
	//       })
	//   }, [data]);
	const [dataVersion, setDataVersion] = useState(0); // Track changes in data

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [studentListRes, branchRes, courseRes] = await Promise.all([
					axios.get(get_student_list),
					axios.get(get_branch),
					axios.get(get_course)
				]);
				setData(studentListRes.data.data);
				setBranchOptions(branchRes.data.data);
				setCourseOptions(courseRes.data.data);
			} catch (err) {
				toast.error(err.response.data.message);
			}
		};

		fetchData();
	}, [dataVersion]); // Re-run effect only when dataVersion changes

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) {
			return;
		}
		const ctx = canvas.getContext('2d');
		const img = new Image();

		img.onload = () => {
			canvas.width = 2100;
			canvas.height = 1500;
			ctx.drawImage(img, 0, 0, 2100, 1500);
			let fullname = '';

			// Add first name if present
			if (formData.first_name) {
				fullname += formData.first_name;
			}

			// Add middle name if present and append hyphen if next field exists
			if (formData.middle_name) {
				if (fullname) {
					fullname += ' ';
				}
				fullname += formData.middle_name;
			}

			// Add last name if present and append hyphen if next field exists
			if (formData.last_name) {
				if (fullname) {
					fullname += ' ';
				}
				fullname += formData.last_name;
			}

			ctx.font = '100 100px Yatra One';
			ctx.fillStyle = '#005480';
			const words = fullname.split(' '); // Split the full name into an array of words
			const wordSpacing = 50; // Adjust this value for desired word spacing
			let xPos = (canvas.width - ctx.measureText(fullname).width) / 2;

			for (let i = 0; i < words.length; i++) {
				ctx.fillText(words[i], xPos, 850);
				xPos += ctx.measureText(words[i]).width + wordSpacing;
			}

			ctx.font = '900 65px DM Sans';
			ctx.fillStyle = '#005480';
			const letterSpacing = 4; // Adjust the letter spacing as needed
			const text = formData.course; // Replace with your text
			let xPosi = (canvas.width - ctx.measureText(text).width) / 2.1;

			// Loop through each character in the text
			for (let i = 0; i < text.length; i++) {
				// Draw each character individually with adjusted x position
				ctx.fillText(text[i], xPosi, 1030);
				// Increase the x position by the width of the character and letter spacing
				xPosi += ctx.measureText(text[i]).width + letterSpacing;
			}

			ctx.font = '500 45px Poppins';
			ctx.fillStyle = 'black';
			ctx.fillText(dateToString(formData.course_completion_date), 490, 1265);

			ctx.font = '500 45px Poppins';
			ctx.fillStyle = 'black';
			ctx.fillText(formData.certificate_number, 1390, 1265);
		};
		img.src = imageSrc;
	})
	// Function to trigger re-fetching data
	const reloadData = () => {
		setTimeout(() => {
			setDataVersion(prev => prev + 1); // Increment dataVersion to trigger effect
		}, 300)
	};
	const [courseOptions, setCourseOptions] = useState([]);
	const [branchOptions, setBranchOptions] = useState([]);
	const [formData, setFormData] = useState({
		certificate_number: "",
		first_name: "",
		middle_name: "",
		last_name: "",
		course: "",
		branch: "",
		course_completion_date: "",
		check: false,
	});
	const [view, setView] = useState({
		edit: false,
		add: false,
		certificate: false,
	});
	const [onSearchText, setSearchText] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage] = useState(50);

	// function to close the form modal
	const onFormCancel = () => {
		setView({ edit: false, add: false, certificate: false });
		resetForm();
	};

	const resetForm = () => {
		setFormData({
			first_name: "",
			middle_name: "",
			last_name: "",
			course: "",
			branch: "",
			course_completion_date: "",
		});
		reset({});
	};

	const onFormSubmit = (form) => {
		const { first_name, middle_name, last_name, course, branch, course_completion_date } = form;
		let submittedData = {
			first_name: first_name,
			middle_name: middle_name,
			last_name: last_name,
			course: course,
			branch: branch,
			course_completion_date: dateToString(course_completion_date),

		};
		axios.post(create_student, submittedData)
			.then(res => {
				toast.success(res.data.message);
			})
			.catch(err => {
				toast.error(err.response.data.message);
			})
		resetForm();
		setView({ open: false });
		reloadData();
	};

	const onEditSubmit = () => {
		let submittedData = {
			certificate_number: formData.certificate_number,
			first_name: formData.first_name,
			middle_name: formData.middle_name,
			last_name: formData.last_name,
			course: formData.course,
			branch: formData.branch,
			course_completion_date: dateToString(formData.course_completion_date),
		};

		axios.put(update_student, submittedData, {
			headers: { 'Content-Type': 'application/json' },
		})
			.then(res => {
				toast.success(res.data.message);
				resetForm();
				setView({ edit: false, add: false });
			})
			.catch(error => {
				if (error.response) {
					toast.error(error.response.data.message);
				} else {
					toast.error("An error occurred while updating the record.");
				}
			});
		reloadData();
	};

	// function that loads the want to editted data
	const onEditClick = (certificate_number) => {
		axios.get(get_student + certificate_number)
			.then(res => {
				const { data, message, success } = res.data
				if (success) {
					if (!data) {
						toast.error(message);
					}
					else {
						const { certificate_number, first_name, middle_name, last_name, course, branch, course_completion_date } = data;
						setFormData({
							certificate_number: certificate_number,
							first_name: first_name,
							middle_name: middle_name,
							last_name: last_name,
							course: course,
							branch: branch,
							course_completion_date: reverseDateString(course_completion_date),
							check: false,
						})
					}
				}
				else {
					toast.error("Something went wrong");
				}
			})
			.catch(err => {
				toast.error(err.response.data.message);
			});
		setView({ add: false, edit: true });
	};

	useEffect(() => {
		reset(formData)
	}, [formData]);

	// selects all the products
	const selectorCheck = (e) => {
		let newData;
		newData = data.map((item) => {
			item.checked = e.currentTarget.checked;
			return item;
		});
		setData([...newData]);
		if (e.currentTarget.checked) {
			setBulkData(data.map(item => item.certificate_number));
		}
		else {
			setBulkData([]);
		}
	};

	const onSelectChange = (e, certificate_number) => {
		let newData = data;
		let index = newData.findIndex((item) => item.certificate_number === certificate_number);
		newData[index].checked = e.currentTarget.checked;
		setData([...newData]);
		if (bulkData.includes(certificate_number)) {
			setBulkData(bulkData.filter((item) => item !== certificate_number));
		}
		else {
			setBulkData([...bulkData, certificate_number]);
		}
	};

	// function to delete a product
	const deleteStudent = (certificate_number) => {
		axios.delete(delete_student + certificate_number)
			.then(res => {
				toast.success(res.data.message);
			})
			.catch(err => {
				toast.error(err.response.data.message);
			})
		reloadData();
	};

	// toggle function to view product certificate
	const toggle = (type) => {
		setView({
			edit: type === "edit" ? true : false,
			add: type === "add" ? true : false,
			certificate: type === "certificate" ? true : false,
		});
	};

	// Get current list, pagination
	const [selectedBranch, setSelectedBranch] = useState('');
	const [selectedCourse, setSelectedCourse] = useState('');
	const [selectedYear, setSelectedYear] = useState('');

	const currentYear = new Date().getFullYear();
	const years = Array.from({ length: currentYear - 2023 + 1 }, (_, i) => 2023 + i).reverse();

	const indexOfLastItem = currentPage * itemPerPage;
	const indexOfFirstItem = indexOfLastItem - itemPerPage;

	// Filter function
	const filteredData = data.filter(
		(result) =>
			result.first_name.toLowerCase().includes(onSearchText.toLowerCase()) ||
			result.middle_name.toLowerCase().includes(onSearchText.toLowerCase()) ||
			result.last_name.toLowerCase().includes(onSearchText.toLowerCase())
	);

	const filterBranchDropdown = selectedBranch
		? filteredData.filter((result) => result.branch === selectedBranch)
		: filteredData;

	const filterCourseDropdown = selectedCourse
		? filterBranchDropdown.filter((result) => result.course === selectedCourse)
		: filterBranchDropdown;

	const filterYearDropdown = selectedYear
		? filterCourseDropdown.filter((result) => {
			const dateParts = result.course_completion_date.split('-');
			return dateParts[1] === selectedYear;
		})
		: filterCourseDropdown;

	const totalItems = filterYearDropdown.length;
	const currentItems = filterYearDropdown.slice(indexOfFirstItem, indexOfLastItem);

	// Pagination function
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// Event handlers for filter changes
	const handleBranchFilterChange = (event) => {
		setSelectedBranch(event.target.value);
		setCurrentPage(1); // Reset pagination to the first page when filter changes
	};

	const handleYearFilterChange = (event) => {
		setSelectedYear(event.target.value);
		setCurrentPage(1); // Reset pagination to the first page when filter changes
	};

	const handleCourseFilterChange = (event) => {
		setSelectedCourse(event.target.value);
		setCurrentPage(1); // Reset pagination to the first page when filter changes
	};

	const handleSearchTextChange = (event) => {
		setSearchText(event.target.value);
		setCurrentPage(1); // Reset pagination to the first page when search text changes
	};
	function download_png() {
		const canvas = canvasRef.current;
		const link = document.createElement('a');
		let filename = '';

		// Add first name if present
		if (formData.first_name) {
			filename += formData.first_name;
		}

		// Add middle name if present and append hyphen if next field exists
		if (formData.middle_name) {
			if (filename && (formData.last_name || formData.course)) {
				filename += '-';
			}
			filename += formData.middle_name;
		}

		// Add last name if present and append hyphen if next field exists
		if (formData.last_name) {
			if (filename && formData.course) {
				filename += '-';
			}
			else if (filename) {
				filename += '-';
			}
			filename += formData.last_name;
		}

		// Add course if present
		if (formData.course) {
			if (filename) {
				filename += '-';
			}
			filename += formData.course;
		}

		// Add file extension
		filename += '.png';
		link.download = filename;
		link.href = canvas.toDataURL();
		link.click();
	}
	const { register, handleSubmit, reset, formState: { errors } } = useForm();

	function reverseDateString(dateString) {
		const [monthAbbreviation, year] = dateString.split('-');
		const monthNumeric = (new Date(`${monthAbbreviation} 1, ${year}`)).getMonth() + 1;
		const monthFormatted = monthNumeric < 10 ? `0${monthNumeric}` : monthNumeric;
		return `${year}-${monthFormatted}`;
	}
	function dateToString(dateString) {
		const date = new Date(dateString);
		const month = date.toLocaleString('default', { month: 'short' });
		const year = date.getFullYear();
		return `${month}-${year}`;
	}
	const currentDate = new Date();
	const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure double digits

	const maxDate = `${currentYear}-${currentMonth}`;
	const bulkDownload = async () => {
		const bodyData = {
			certificate_numbers: bulkData

		};
		if (bodyData.certificate_numbers.length > 0) {
			await axios.post(get_specific_student_data, bodyData)
				.then(res => {
					downloadCertificatesAsZip(res.data.data);
				})
				.catch(err => {
					toast.error(err.response.data.message);
				})
		}
		else {
			toast.error("Please select atleast one certificate");
		}

	}
	const bulkDelete = async () => {
		const bodyData = {
			certificate_numbers: bulkData
		};
		if (bodyData.certificate_numbers.length > 0) {
			await axios.request({
				method: 'DELETE',
				url: delete_multiple_student,
				headers: {
					'Content-Type': 'application/json',
				},
				data: bodyData, // Data to be sent in the body
			})
				.then((response) => {
					toast.success(response.data.message);
				})
				.catch(error => {
					toast.error(error.response.data.message);
				})
		}
		else {
			toast.error("Please select atleast one certificate");
		}
	}
	const onPrintChange = (e, certificate_number) => {
		const submittedData = {
			certificate_number: certificate_number,
			print_status: "No"
		}
		if (e.target.checked) {
			submittedData.print_status = "Yes"
		}
		axios.put(update_yes_no, submittedData, {
			headers: { 'Content-Type': 'application/json' },
		})
			.then(res => {
				reloadData();
			})
			.catch(error => {
				if (error.response) {
					toast.error(error.response.data.message);
				} else {
					toast.error("An error occurred while updating the record.");
				}
			});
	}
	function generateCertificateCanvas(data) {
		return new Promise((resolve, reject) => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			const img = new Image();

			img.onload = () => {
				canvas.width = 2100;
				canvas.height = 1500;
				ctx.drawImage(img, 0, 0, 2100, 1500);

				// Extract data from 'data' object
				const { first_name, middle_name, last_name, course, course_completion_date, certificate_number } = data;

				// Draw full name
				let fullname = '';

				// Add first name if present
				if (first_name) {
					fullname += first_name;
				}

				// Add middle name if present and append hyphen if next field exists
				if (middle_name) {
					if (fullname) {
						fullname += ' ';
					}
					fullname += middle_name;
				}

				// Add last name if present and append hyphen if next field exists
				if (last_name) {
					if (fullname) {
						fullname += ' ';
					}
					fullname += last_name;
				}

				ctx.font = '100 100px Yatra One';
				ctx.fillStyle = '#005480';
				const words = fullname.split(' '); // Split the full name into an array of words
				const wordSpacing = 50; // Adjust this value for desired word spacing
				let xPos = (canvas.width - ctx.measureText(fullname).width) / 2;

				for (let i = 0; i < words.length; i++) {
					ctx.fillText(words[i], xPos, 850);
					xPos += ctx.measureText(words[i]).width + wordSpacing;
				}

				ctx.font = '900 65px DM Sans';
				ctx.fillStyle = '#005480';
				const letterSpacing = 4; // Adjust the letter spacing as needed
				const text = course; // Replace with your text
				let xPosi = (canvas.width - ctx.measureText(text).width) / 2.1;

				// Loop through each character in the text
				for (let i = 0; i < text.length; i++) {
					// Draw each character individually with adjusted x position
					ctx.fillText(text[i], xPosi, 1030);
					// Increase the x position by the width of the character and letter spacing
					xPosi += ctx.measureText(text[i]).width + letterSpacing;
				}

				ctx.font = '500 45px Poppins';
				ctx.fillStyle = 'black';
				ctx.fillText(dateToString(course_completion_date), 490, 1265);

				ctx.font = '500 45px Poppins';
				ctx.fillStyle = 'black';
				ctx.fillText(certificate_number, 1390, 1265);
				resolve(canvas);
			};

			img.onerror = (err) => reject(err);
			img.src = imageSrc; // Assuming imageSrc is part of your data object
		});
	}

	// Function to download multiple certificate images as a zip file
	function downloadCertificatesAsZip(certificatesData) {
		const zip = new JSZip();

		// Array to store all promises
		const promises = certificatesData.map((data, index) => {
			return generateCertificateCanvas(data).then((canvas) => {
				// Convert canvas to base64 PNG data URL
				const imageDataUrl = canvas.toDataURL('image/png').split(',')[1]; // Get base64 data part

				// Add to zip file
				zip.file(`${data.first_name}-${data.middle_name}-${data.last_name}-${data.course} .png`, imageDataUrl, { base64: true });
			});
		});

		// After all promises are resolved, generate and download zip file
		Promise.all(promises).then(() => {
			zip.generateAsync({ type: 'blob' }).then((content) => {
				// Create download link
				const link = document.createElement('a');
				link.href = URL.createObjectURL(content);
				link.download = 'certificates.zip';
				link.click();
				reloadData();
				setBulkData([]);
			});
		});
	}
	return (
		<React.Fragment>
			<Head title="Add Certificate"></Head>
			<Content>
				<BlockHead size="md">
					<div className="mt-3">
						<BlockBetween>
							<BlockHeadContent>
								<BlockTitle page>Add Certificate</BlockTitle>
							</BlockHeadContent>
							<BlockHeadContent>
								<div className="toggle-wrap nk-block-tools-toggle">
									<a
										href="#more"
										className="btn btn-icon btn-trigger toggle-expand me-n1"
										onClick={(ev) => {
											ev.preventDefault();
											updateSm(!sm);
										}}
									>
										<Icon name="more-v"></Icon>
									</a>
									<div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }} >
										<ul className={sm ? "nk-block-tools g-3 d-flex flex-column" : "nk-block-tools g-3"}>
											<li>
												<div className="form-control-wrap">
													<div className="form-icon form-icon-right">
														<Icon name="search"></Icon>
													</div>
													<input
														autoComplete="off"
														type="text"
														className={sm ? "form-control w-200px" : "form-control"}
														id="default-04"
														placeholder="Search here..."
														onChange={(e) => { handleSearchTextChange(e); reloadData(); }}
													/>
												</div>
											</li>

											<li>
												<div className="form-control-select">
													<Input type="select" name="select" value={selectedBranch} onChange={(e) => { handleBranchFilterChange(e); reloadData(); }} className={sm ? "form-control w-200px" : "form-control"}>
														<option value="">Select Branch</option>
														{branchOptions.map((branch) => (
															<option value={branch.value}>
																{branch.value}
															</option>
														))}
													</Input>
												</div>
											</li>
											<li>

												<div className="form-control-select">
													<Input type="select" name="select" value={selectedCourse} onChange={(e) => { handleCourseFilterChange(e); reloadData(); }} className={sm ? "form-control w-max-200px w-200px" : "form-control w-max-200px"}>
														<option value="">Select Course</option>
														{courseOptions.map((course) => (
															<option value={course.value}>
																{course.value}
															</option>
														))}
													</Input>
												</div>
											</li>
											<li>
												<div className="form-control-select">
													<Form.Select value={selectedYear} onChange={(e) => { handleYearFilterChange(e); reloadData(); }} className={sm ? "w-200px" : "form-control"}>
														<option value="">Select Year</option>
														{years.map((year) => (
															<option key={year} value={year}>
																{year}
															</option>
														))}
													</Form.Select>
												</div>
											</li>
											<li className="form-control-wrap">
												{/* <Button
                        className="toggle btn-icon d-md-none"
                        color="primary"
                        onClick={() => {
                          toggle("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button> */}
												<Button
													className={sm ? "w-200px" : "toggle d-md-inline-flex"}
													color="primary"
													onClick={() => {
														toggle("add");
													}}
												>
													<Icon name="plus"></Icon>
													<span>Add Certificate</span>
												</Button>
											</li>
										</ul>
									</div>
								</div>
							</BlockHeadContent>
						</BlockBetween>
					</div>
				</BlockHead>

				<Block>
					<div className="table-responsive">
						<div className="nk-tb-list is-separate is-medium mb-3">
							<DataTableBody>
								<DataTableHead className="nk-tb-item">
									<DataTableRow className="nk-tb-col-check">
										<div className="custom-control custom-control-sm custom-checkbox notext">
											<input
												type="checkbox"
												className="custom-control-input"
												id="uid_1"
												onChange={(e) => selectorCheck(e)}
											/>
											<label className="custom-control-label" htmlFor="uid_1"></label>
										</div>
									</DataTableRow>
									<DataTableRow>
										<span>Sr No.</span>
									</DataTableRow>
									<DataTableRow>
										<span>Certificate Number</span>
									</DataTableRow>
									<DataTableRow>
										<span>Name</span>
									</DataTableRow>
									<DataTableRow>
										<span>Course</span>
									</DataTableRow>
									<DataTableRow>
										<span>Branch</span>
									</DataTableRow>
									<DataTableRow>
										<span>Course Completion Date</span>
									</DataTableRow>
									<DataTableRow>
										<span>Print Status</span>
									</DataTableRow>
									<DataTableRow className="nk-tb-col-tools text-end">
										<UncontrolledDropdown>
											<DropdownToggle color="tranparent" className="dropdown-toggle btn btn-icon btn-trigger me-n1">
												<Icon name="more-h"></Icon>
											</DropdownToggle>
											<DropdownMenu end>
												<ul className="link-list-opt no-bdr">
													<li>
														<DropdownItem
															tag="a"
															href="#"
															onClick={(ev) => {
																ev.preventDefault();
																bulkDownload();
																// selectorDeleteUser();
															}}
														>
															<Icon name="download"></Icon>
															<span>Download Selected</span>
														</DropdownItem>
													</li>
													<li>
														<DropdownItem
															tag="a"
															href="#"
															onClick={(ev) => {
																ev.preventDefault();
																bulkDelete();
																// selectorSuspendUser();
															}}
														>
															<Icon name="trash"></Icon>
															<span>Remove Selected</span>
														</DropdownItem>
													</li>
													{/* <li>
														<DropdownItem
															tag="a"
															href="#"
															onClick={(ev) => {
																ev.preventDefault();
																// selectorSuspendUser();
															}}
														>
															<Icon name="trash"></Icon>
															<span>Change Selected</span>
														</DropdownItem>
													</li> */}
												</ul>
											</DropdownMenu>
										</UncontrolledDropdown>
									</DataTableRow>
								</DataTableHead>
								{(currentItems).length > 0
									? (currentItems).map((item, index) => {
										index += 1;
										return (
											<DataTableItem key={index} >
												<DataTableRow className="nk-tb-col-check" style={{ backgroundColor: item.print_status == "Yes" ? theme.skin == "dark" ? "#28374b" : "#fafafa" : "" }}>
													<div className="custom-control custom-control-sm custom-checkbox notext">
														<input
															type="checkbox"
															className="custom-control-input"
															defaultChecked={item.checked}
															id={index + "uid1"}
															key={Math.random()}
															onChange={(e) => onSelectChange(e, item.certificate_number)}
														/>
														<label className="custom-control-label" htmlFor={index + "uid1"}></label>
													</div>
												</DataTableRow>
												<DataTableRow style={{ backgroundColor: item.print_status == "Yes" ? theme.skin == "dark" ? "#28374b" : "#fafafa" : "" }}>
													<span className="tb-col"  >{index}</span>
												</DataTableRow>
												<DataTableRow style={{ backgroundColor: item.print_status == "Yes" ? theme.skin == "dark" ? "#28374b" : "#fafafa" : "" }}>
													<span className="tb-sub">{item.certificate_number}</span>
												</DataTableRow>
												<DataTableRow style={{ backgroundColor: item.print_status == "Yes" ? theme.skin == "dark" ? "#28374b" : "#fafafa" : "" }}>
													<span className="tb-product">
														{/* <img src={item.img ? item.img : ProductH} alt="product" className="thumb" /> */}
														<span className="title">{item.first_name + " " + item.middle_name + " " + item.last_name}</span>
													</span>
												</DataTableRow>
												<DataTableRow style={{ backgroundColor: item.print_status == "Yes" ? theme.skin == "dark" ? "#28374b" : "#fafafa" : "" }}>
													<span className="tb-sub">{item.course}</span>
												</DataTableRow>
												<DataTableRow style={{ backgroundColor: item.print_status == "Yes" ? theme.skin == "dark" ? "#28374b" : "#fafafa" : "" }}>
													<span className="tb-sub">{item.branch}</span>
												</DataTableRow>
												<DataTableRow style={{ backgroundColor: item.print_status == "Yes" ? theme.skin == "dark" ? "#28374b" : "#fafafa" : "" }}>
													<span className="tb-sub">{item.course_completion_date}</span>
												</DataTableRow>
												<DataTableRow style={{ backgroundColor: item.print_status == "Yes" ? theme.skin == "dark" ? "#28374b" : "#fafafa" : "" }}>
													<span className="tb-sub">
														<div class="form-check form-switch">
															<input class="form-check-input" style={{ scale: "1.5" }} type="checkbox" id="flexSwitchCheckDefault" onClick={(e) => onPrintChange(e, item.certificate_number)} checked={item.print_status == "Yes" ? true : false} />
															<label style={{ marginLeft: "10px" }} class="form-check-label" for="flexSwitchCheckDefault">{item.print_status}</label>
														</div>
													</span>
												</DataTableRow>
												<DataTableRow style={{ backgroundColor: item.print_status == "Yes" ? theme.skin == "dark" ? "#28374b" : "#fafafa" : "" }}>
													<div className="btn-group float-end">
														<Button onClick={(ev) => {
															ev.preventDefault();
															onEditClick(item.certificate_number);
															toggle("edit");
														}} className="	 btn-outline-primary">
															<Icon name="edit"></Icon>
														</Button>
														<Button onClick={(ev) => {
															ev.preventDefault();
															onEditClick(item.certificate_number);
															toggle("certificate");
														}} className="	 btn-outline-primary">
															<Icon name="eye"></Icon>
														</Button>
														<Button onClick={(ev) => {
															ev.preventDefault();
															deleteStudent(item.certificate_number);
														}} className="	 btn-outline-primary">
															<Icon name="trash"></Icon>
														</Button>
													</div>
												</DataTableRow>

											</DataTableItem>
										);
									})
									: null}
							</DataTableBody>
						</div>
					</div>
					<PreviewAltCard>
						{totalItems > 0 ? (
							<PaginationComponent
								itemPerPage={itemPerPage}
								totalItems={totalItems}
								paginate={paginate}
								currentPage={currentPage}
							/>
						) : (
							<div className="text-center">
								<span className="text-silent">No Student found</span>
							</div>
						)}
					</PreviewAltCard>
				</Block>

				<Modal isOpen={view.edit} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a href="#cancel" className="close">
							{" "}
							<Icon
								name="cross-sm"
								onClick={(ev) => {
									ev.preventDefault();
									onFormCancel();
								}}
							></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">Update Certificate</h5>
							<div className="mt-4">
								<form onSubmit={handleSubmit(onEditSubmit)} autoComplete="off">
									<Row className="g-3">
										<Col size="4">
											<div className="form-group">
												<label className="form-label" htmlFor="product-title">
													First Name<span className="text-danger">*</span>
												</label>
												<div className="form-control-wrap">
													<input
														type="text"
														className="form-control"
														{...register('first_name', { required: "This is required" })}
														value={formData.first_name}
														onChange={(e) => setFormData({ ...formData, first_name: e.target.value })} />
													{errors.first_name && <span className="invalid">{errors.first_name.message}</span>}
												</div>
											</div>
										</Col>
										<Col size="4">
											<div className="form-group">
												<label className="form-label" htmlFor="product-title">
													Middle Name
												</label>
												<div className="form-control-wrap">
													<input
														type="text"
														className="form-control"
														value={formData.middle_name}
														onChange={(e) => setFormData({ ...formData, middle_name: e.target.value })} />
												</div>
											</div>
										</Col>
										<Col size="4">
											<div className="form-group">
												<label className="form-label" htmlFor="product-title">
													Last Name<span className="text-danger">*</span>
												</label>
												<div className="form-control-wrap">
													<input
														type="text"
														className="form-control"
														{...register('last_name', { required: "This is required" })}
														value={formData.last_name}
														onChange={(e) => setFormData({ ...formData, last_name: e.target.value })} />
													{errors.last_name && <span className="invalid">{errors.last_name.message}</span>}
												</div>
											</div>
										</Col>
										<Col size="12">
											<div className="form-group">
												<label className="form-label" htmlFor="course">
													Course<span className="text-danger">*</span>
												</label>
												<div className="form-control-wrap">
													<div className="form-control-select">
														<Input type="select" name="select" {...register('course', { required: "This is required" })} value={formData.course} onChange={(e) => setFormData({ ...formData, course: e.target.value })} >
															<option value="">Select Course</option>
															{courseOptions.map((course) => (
																<option value={course.value}>
																	{course.value}
																</option>
															))}
														</Input>
														{errors.course && <span className="invalid">{errors.course.message}</span>}
													</div>
												</div>
											</div>
										</Col>
										<Col size="12">
											<div className="form-group">
												<label className="form-label" htmlFor="branch">
													Branch<span className="text-danger">*</span>
												</label>
												<div className="form-control-wrap">
													<div className="form-control-select">
														<Input type="select" name="select" {...register('branch', { required: "This is required" })} value={formData.branch} onChange={(e) => setFormData({ ...formData, branch: e.target.value })}>
															<option value="">Select Branch</option>
															{branchOptions.map((branch) => (
																<option value={branch.value}>
																	{branch.value}
																</option>
															))}
														</Input>
														{errors.branch && <span className="invalid">{errors.branch.message}</span>}
													</div>
												</div>
											</div>
										</Col>
										<Col size="12">
											<div className="form-group">
												<label className="form-label" htmlFor="course_completion_date">
													Course Completion Date<span className="text-danger">*</span>
												</label>
												<div className="form-control-wrap">
													<input type="month" className="form-control date-picker"
														{...register('course_completion_date', { required: "This is required" })}
														value={formData.course_completion_date}
														onChange={(e) => {
															setFormData({ ...formData, course_completion_date: e.target.value })
														}}
														max={maxDate} />
													{errors.course_completion_date && <span className="invalid">{errors.course_completion_date.message}</span>}
												</div>
											</div>
										</Col>

										<Col size="12">
											<Button color="primary" type="submit">
												<Icon name="edit"></Icon>
												<span>Update Certificate</span>
											</Button>
										</Col>
									</Row>
								</form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<Modal size="lg" isOpen={view.certificate} toggle={() => onFormCancel()}>
					<ModalBody >
						<a href="#cancel" className="close">
							{" "}
							<Icon
								name="cross-sm"
								onClick={(ev) => {
									ev.preventDefault();
									onFormCancel();
								}}
							></Icon>
						</a>
						<div >
							<CertificateGenerator first_name={formData.first_name} middle_name={formData.middle_name} last_name={formData.last_name} course={formData.course} course_completion_date={dateToString(formData.course_completion_date)} certificate_number={formData.certificate_number} canvasRef={canvasRef} />
						</div>
					</ModalBody>
					<ModalFooter className="bg-light">
						<Button color="primary" size="sm" className="btn-icon btn-primary p-3" onClick={download_png}>
							<Icon name="arrow-down-fill-c"></Icon>Download
						</Button>
					</ModalFooter>
				</Modal>
				<SimpleBar
					className={`nk-add-product toggle-slide toggle-slide-right toggle-screen-any ${view.add ? "content-active" : ""
						}`}
				>
					<BlockHead>
						<BlockHeadContent>
							<BlockTitle tag="h5">Add Certificate</BlockTitle>
							<BlockDes>
								<p>Please enter student information.</p>
							</BlockDes>
						</BlockHeadContent>
					</BlockHead>
					<Block>
						<form onSubmit={handleSubmit(onFormSubmit)} autoComplete="off">
							<Row className="g-3">
								<Col size="12">
									<div className="form-group">
										<label className="form-label" htmlFor="First Name">
											First Name<span className="text-danger">*</span>
										</label>
										<div className="form-control-wrap">
											<input
												type="text"
												className="form-control"
												{...register('first_name', { required: "This is required" })}
												value={formData.first_name}
												onChange={(e) => setFormData({ ...formData, first_name: e.target.value })} />
											{errors.first_name && <span className="invalid">{errors.first_name.message}</span>}

										</div>
									</div>
								</Col>
								<Col size="12">
									<div className="form-group">
										<label className="form-label" htmlFor="Middle Name">
											Middle Name
										</label>
										<div className="form-control-wrap">
											<input
												type="text"
												className="form-control"
												value={formData.middle_name}
												onChange={(e) => setFormData({ ...formData, middle_name: e.target.value })} />
										</div>
									</div>
								</Col>
								<Col size="12">
									<div className="form-group">
										<label className="form-label" htmlFor="Last Name">
											Last Name<span className="text-danger">*</span>
										</label>
										<div className="form-control-wrap">
											<input
												type="text"
												className="form-control"
												{...register('last_name', { required: "This is required" })}
												value={formData.last_name}
												onChange={(e) => setFormData({ ...formData, last_name: e.target.value })} />
											{errors.last_name && <span className="invalid">{errors.last_name.message}</span>}

										</div>
									</div>
								</Col>

								<Col size="12">
									<div className="form-group">
										<label className="form-label" htmlFor="category">
											Course<span className="text-danger">*</span>
										</label>
										<div className="form-control-wrap">
											<div className="form-control-select">
												<Input type="select" name="select" id="default-4" {...register('course', { required: "This is required" })} value={formData.course} onChange={(e) => setFormData({ ...formData, course: e.target.value })}>
													<option value="">Select Course</option>
													{courseOptions.map((course) => (
														<option value={course.value}>
															{course.value}
														</option>
													))}
												</Input>
												{errors.course && <span className="invalid">{errors.course.message}</span>}
											</div>
										</div>
									</div>
								</Col>
								<Col size="12">
									<div className="form-group">
										<label className="form-label" htmlFor="category">
											Branch<span className="text-danger">*</span>
										</label>
										<div className="form-control-wrap">
											<div className="form-control-select">
												<Input type="select" name="select" className="form-control-select" id="default-4" {...register('branch', { required: "This is required" })} value={formData.branch} onChange={(e) => setFormData({ ...formData, branch: e.target.value })}>
													<option value="">Select Branch</option>
													{branchOptions.map((branch) => (
														<option value={branch.value}>
															{branch.value}
														</option>
													))}
												</Input>
												{errors.branch && <span className="invalid">{errors.branch.message}</span>}
											</div>
										</div>
									</div>
								</Col>
								<Col size="12">
									<div className="form-group">
										<label className="form-label" htmlFor="category">
											Course Completion Date<span className="text-danger">*</span>
										</label>
										<div className="form-control-wrap">
											<input
												type="month"
												className="form-control date-picker"
												{...register('course_completion_date', { required: "This is required" })}
												onChange={(e) => {
													setFormData({ ...formData, course_completion_date: e.target.value })
												}}
												max={maxDate}
											/>
											{errors.course_completion_date && <span className="invalid">{errors.course_completion_date.message}</span>}

										</div>
									</div>
								</Col>
								<Col size="12">
									<Button color="primary" type="submit">
										<Icon name="plus"></Icon>
										<span>Add Certificate</span>
									</Button>
								</Col>
							</Row>
						</form>
					</Block>
				</SimpleBar>

				{view.add && <div className="toggle-overlay" onClick={toggle}></div>}
			</Content>
			<ToastContainer position="bottom-right" hideProgressBar="true" />
			{imageSrc && (
				<canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
			)}
		</React.Fragment >
	);
};

export default AddCertificate;