import React, { useEffect, useRef, useState } from 'react'
import Head from '../layout/head/Head'
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from '../components/Block'
import Icon from '../components/Icon'
import { Button, ModalFooter, Modal, ModalBody } from 'reactstrap'
import axios from 'axios'
import { get_student } from '../utils/constants'
import { Link } from 'react-router-dom'
import CertificateGenerator from '../certificate-generator/CertificateGenerator'
import { PreviewCard } from '../components/Preview'
import SITHLOGOWHITE from "../images/SITH_LOGO_WHITE.png";
import SITHLOGOBLACK from "../images/SITH_LOGO_BLACK.png";
import AuthFooter from '../Auth/AuthFooter'
import { ToastContainer, toast } from 'react-toastify'
import CertificateTemplate from '../images/Website Certificate SITH.jpg'

function StudentCertificate() {
    const [data, setData] = useState([]);
    const [certificate_number, setCertificate_number] = useState("");
    const [modalXl, setModalXl] = useState(false);
    const [imageSrc] = useState(CertificateTemplate);
    const canvasRef = useRef(null);
    const toggleXl = () => setModalXl(!modalXl);
    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) {
            // console.error("Canvas element is not available");
            return;
        }
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.onload = () => {
            canvas.width = 2100;
            canvas.height = 1500;
            ctx.drawImage(img, 0, 0, 2100, 1500);
            let fullname = '';

            // Add first name if present
            if (data.first_name) {
                fullname += data.first_name;
            }

            // Add middle name if present and append hyphen if next field exists
            if (data.middle_name) {
                if (fullname) {
                    fullname += ' ';
                }
                fullname += data.middle_name;
            }

            // Add last name if present and append hyphen if next field exists
            if (data.last_name) {
                if (fullname) {
                    fullname += ' ';
                }
                fullname += data.last_name;
            }

            ctx.font = '100 100px Yatra One';
            ctx.fillStyle = '#005480';
            const words = fullname.split(' '); // Split the full name into an array of words
            const wordSpacing = 0; // Adjust this value for desired word spacing
            let xPos = (canvas.width - ctx.measureText(fullname).width) / 2;

            for (let i = 0; i < words.length; i++) {
                ctx.fillText(words[i], xPos, 850);
                xPos += ctx.measureText(words[i]).width + wordSpacing;
            }

            if (data.course) {
                ctx.font = '900 65px DM Sans';
                ctx.fillStyle = '#005480';
                const letterSpacing = 4; // Adjust the letter spacing as needed
                const text = data.course; // Replace with your text
                let xPosi = (canvas.width - ctx.measureText(text).width) / 2.1;

                // Loop through each character in the text
                for (let i = 0; i < text.length; i++) {
                    // Draw each character individually with adjusted x position
                    ctx.fillText(text[i], xPosi, 1030);
                    // Increase the x position by the width of the character and letter spacing
                    xPosi += ctx.measureText(text[i]).width + letterSpacing;
                }
            }
            ctx.font = '500 45px Poppins';
            ctx.fillStyle = 'black';
            ctx.fillText(data.course_completion_date, 490, 1265);

            ctx.font = '500 45px Poppins';
            ctx.fillStyle = 'black';
            ctx.fillText(data.certificate_number, 1390, 1265);
        };
        img.src = imageSrc;
    })
    const displayCertificate = () => {
        if (certificate_number.length > 0) {
            axios.get(get_student + certificate_number)
                .then(res => {
                    const { data, message, success } = res.data
                    if (success) {
                        if (!data) {
                            toast.error(message);
                        }
                        else {
                            const { certificate_number, first_name, middle_name, last_name, course, branch, course_completion_date } = data;
                            setData({
                                certificate_number: certificate_number,
                                first_name: first_name,
                                middle_name: middle_name,
                                last_name: last_name,
                                course: course,
                                branch: branch,
                                course_completion_date: course_completion_date,
                                check: false,
                            })
                            toggleXl();
                        }
                    }
                    else {
                        toast.error("Something went wrong");
                    }
                })
                .catch(err => {
                    toast.error(err.response.data.message);
                });

        } else {
            toast.error("Please enter certificate number");
        }
    };
    function download_png() {
        const canvas = canvasRef.current;
        const link = document.createElement('a');
        let filename = '';

        // Add first name if present
        if (data.first_name) {
            filename += data.first_name;
        }

        // Add middle name if present and append hyphen if next field exists
        if (data.middle_name) {
            if (filename && (data.last_name || data.course)) {
                filename += '-';
            }
            filename += data.middle_name;
        }

        // Add last name if present and append hyphen if next field exists
        if (data.last_name) {
            if (filename && data.course) {
                filename += '-';
            }
            else if (filename) {
                filename += '-';
            }
            filename += data.last_name;
        }

        // Add course if present
        if (data.course) {
            if (filename) {
                filename += '-';
            }
            filename += data.course;
        }

        // Add file extension
        filename += '.png';
        link.download = filename;
        link.href = canvas.toDataURL();
        link.click();
    }
    const onFormCancel = () => {
        setModalXl(false);
    };
    return (
        <React.Fragment>
            <ToastContainer position="bottom-right" hideProgressBar="true" />
            <Head title="Certificate" />
            <Block className="nk-block-middle nk-auth-body mt-5 pt-5 wide-xs">
                <div className="brand-logo text-center">
                    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
                        <img className="logo-light logo-img logo-img-lg" src={SITHLOGOWHITE} alt="logo" />
                        <img className="logo-dark logo-img logo-img-lg" src={SITHLOGOBLACK} alt="logo-dark" />
                    </Link>
                </div>
                <PreviewCard className="card-bordered mt-5 " bodyClass="card-inner-lg">
                    <BlockHead>
                        <BlockContent>
                            <BlockTitle tag="h5">Download Certificate</BlockTitle>
                            <BlockDes>
                                <p>Please enter the certificate number provided by the institute.</p>
                            </BlockDes>
                        </BlockContent>
                    </BlockHead>
                    <form onSubmit={(e) => { e.preventDefault(); displayCertificate() }}>
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="default-01">
                                    Certificate Number
                                </label>
                            </div>
                            <div className="form-control-wrap">
                                <div className="form-icon form-icon-right pt-1">
                                    <Icon name="search"></Icon>
                                </div>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="default-01"
                                    placeholder="Please enter your 8 digit certificate number"
                                    maxLength={8}
                                    onChange={(e) => setCertificate_number(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <Button color="primary" size="lg" className="btn-block" type='submit'>
                                View Certificate
                            </Button>
                        </div>
                    </form>
                </PreviewCard>
            </Block>
            <Modal size="lg" isOpen={modalXl} toggle={toggleXl}>
                <ModalBody >
                    <a href="#cancel" className="close">
                        {" "}
                        <Icon
                            name="cross-sm"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                        ></Icon>
                    </a>
                    <div id='img-download' >
                        <CertificateGenerator first_name={data.first_name} middle_name={data.middle_name} last_name={data.last_name} course={data.course} course_completion_date={data.course_completion_date} certificate_number={data.certificate_number} canvasRef={canvasRef} />
                    </div>
                </ModalBody>
                <ModalFooter className="bg-light">
                    <div class="d-flex justify-content-center">
                        <Button color="primary" size="sm" className="btn-icon btn-primary p-3" onClick={download_png}>
                            <Icon name="arrow-down-fill-c"></Icon>Download
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
            <AuthFooter />
            {imageSrc && (
                <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
            )}
        </React.Fragment >
    )
}

export default StudentCertificate