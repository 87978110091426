import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Routes, Route, useLocation } from "react-router-dom";
import Report from '../pages/Dashboard/Reports/Report';
import IssueDate from '../pages/Dashboard/Issue Date/IssueDate';
import AddCertificate from '../pages/Dashboard/Add Certificate/AddCertificate';
import ViewCertificate from '../pages/Dashboard/View Certificate/viewCertificate';
import Login from '../Auth/Login';
import Layout from '../layout/Index';
import LayoutNoSidebar from '../layout/Index-nosidebar';
import { RequireAuth } from '../Auth/RequireAuth';
import StudentCertificate from '../public/studentCertificate';
// import PreLoader from '../components/preloader/PreLoader';
import PageNotFound from '../pages/404 Error/PageNotFound';
import VerfiyPage from '../public/verifyPage';
function Router() {
    const location = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     const handleStartLoading = () => {
    //         setLoading(true);
    //     };

    //     const handleStopLoading = () => {
    //         setLoading(false);
    //     };

    //     const handleRouteChange = () => {
    //         setLoading(true);
    //     };

    //     const handleRouteChangeComplete = () => {
    //         setLoading(false);
    //     };

    //     window.addEventListener('load', handleStopLoading);
    //     window.addEventListener('beforeunload', handleStartLoading);
    //     document.addEventListener('routeChange', handleRouteChange);
    //     document.addEventListener('routeChangeComplete', handleRouteChangeComplete);

    //     return () => {
    //         window.removeEventListener('load', handleStopLoading);
    //         window.removeEventListener('beforeunload', handleStartLoading);
    //         document.removeEventListener('routeChange', handleRouteChange);
    //         document.removeEventListener('routeChangeComplete', handleRouteChangeComplete);
    //     };
    // }, []);

    

    // if (loading) {
    //     return <PreLoader/>;
    // }

    return (
        <Routes>
            <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
                <Route path="/" element={<StudentCertificate />}></Route>
                <Route path="/verify" element={<VerfiyPage />}></Route>
                <Route path="/admin" element={<Login />}></Route>
                <Route path="*" element={<PageNotFound />}></Route>
                
            </Route>
            {/*Dashboards*/}
            <Route path={`${process.env.PUBLIC_URL}`} element={<RequireAuth><Layout /></RequireAuth>}>
                <Route path='add-certificate' element={<AddCertificate />}></Route>
                <Route path="issue-date" element={<IssueDate />}></Route>
                <Route path="reports" element={<Report />}></Route>
                <Route path="view-certificate" element={<ViewCertificate />}></Route>
            </Route>

        </Routes>
    )
}

export default Router
