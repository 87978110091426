export const API_PATH = "https://api.sithcomputers.com";
export const get_student_list=`${API_PATH}/get_student_list`;
export const create_student=`${API_PATH}/create_student`;
export const delete_student=`${API_PATH}/delete_student/`;
export const get_student=`${API_PATH}/get_student/`;
export const update_student=`${API_PATH}/update_student`;
export const filter_student_list=`${API_PATH}/filtered_data`;
export const get_student_datalist=`${API_PATH}/get_student_datalist/`;



export const get_branch=`${API_PATH}/get_branch_list`;
export const delete_branch=`${API_PATH}/delete_branch/`;


export const get_course=`${API_PATH}/get_course_list`;
export const delete_course=`${API_PATH}/delete_course/`;


export const get_report=`${API_PATH}/report`;

export const get_issue_list=`${API_PATH}/get_issued_List/`;
export const add_issue_record=`${API_PATH}/addIssuedRecord/`;
export const delete_issue_record=`${API_PATH}/delete_issued_student/`;

export const admin_login=`${API_PATH}/login`;

export const get_verified=`${API_PATH}/get_verified/`;

export const get_specific_student_data=`${API_PATH}/get_specific_student_data`;
export const delete_multiple_student=`${API_PATH}/delete_multiple_student`;
export const update_yes_no=`${API_PATH}/update_yes_no/`;

