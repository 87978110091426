import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright text-soft">
            <Link className="nav-link" target="_blank" to={`https://sithcomputers.com/`}>
              &copy; 2024 SITH - Solutions for IT. All Rights Reserved
            </Link>
          </div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item">
                <Link className="nav-link" target="_blank" to={`https://sithcomputers.com/all-courses/`}>
                  Courses
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" target="_blank" to={`https://sithcomputers.com/blogs/`}>
                  Blogs
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" target="_blank" to={`https://sithcomputers.com/contact-us/`}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
