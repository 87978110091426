const menu = [
  {
    heading: "Dashboard",
  },
  {
    icon: "plus-fill-c",
    text: "Add Certificate",
    link: "/add-certificate",
  },
  {
    icon: "grid-fill-c",
    text: "View Certificate",
    link: "/view-certificate",
  },
  {
    icon: "growth-fill",
    text: "Certificate Report",
    link: "/reports",
  },
  {
    icon: "calender-date-fill",
    text: "Issued Certificate",
    link: "/issue-date",
  },
];
export default menu;