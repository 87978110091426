import React, { useEffect, useState } from 'react'
import CertificateTemplate from '../images/Website Certificate SITH.jpg'
export default function CertificateGenerator(props) {
  const { first_name, middle_name, last_name, course, course_completion_date, certificate_number, canvasRef } = props;
  // const canvasRef = useRef(null);
  const [imageSrc] = useState(CertificateTemplate);
  // Function to display the image on the canvas
  useEffect(() => {
    displayImageOnCanvas();
  })
  function displayImageOnCanvas() {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = 2100;
      canvas.height = 1500;
      ctx.drawImage(img, 0, 0, 2100, 1500);
      let fullname = '';

      // Add first name if present
      if (first_name) {
        fullname += first_name;
      }

      // Add middle name if present and append hyphen if next field exists
      if (middle_name) {
        if (fullname) {
          fullname += ' ';
        }
        fullname += middle_name;
      }

      // Add last name if present and append hyphen if next field exists
      if (last_name) {
        if (fullname) {
          fullname += ' ';
        }
        fullname += last_name;
      }

      ctx.font = '100 100px Yatra One';
      ctx.fillStyle = '#005480';
      const words = fullname.split(' '); // Split the full name into an array of words
      const wordSpacing = 50; // Adjust this value for desired word spacing
      let xPos = (canvas.width - ctx.measureText(fullname).width) / 2;

      for (let i = 0; i < words.length; i++) {
        ctx.fillText(words[i], xPos, 850);
        xPos += ctx.measureText(words[i]).width + wordSpacing;
      }

      ctx.font = '900 65px DM Sans';
      ctx.fillStyle = '#005480';
      const letterSpacing = 4; // Adjust the letter spacing as needed
      const text = course; // Replace with your text
      let xPosi = (canvas.width - ctx.measureText(text).width) / 2.1;

      // Loop through each character in the text
      for (let i = 0; i < text.length; i++) {
        // Draw each character individually with adjusted x position
        ctx.fillText(text[i], xPosi, 1030);
        // Increase the x position by the width of the character and letter spacing
        xPosi += ctx.measureText(text[i]).width + letterSpacing;
      }

      ctx.font = '500 45px Poppins';
      ctx.fillStyle = 'black';
      ctx.fillText(course_completion_date, 490, 1265);

      ctx.font = '500 45px Poppins';
      ctx.fillStyle = 'black';
      ctx.fillText(certificate_number, 1390, 1265);
    };
    img.src = imageSrc;
  }
  return (
    // <div className='container'>
    // <div className='image-wrapper'>
    //   <img src={CertificateTemplate} alt='certificate' className='certificate-img' loading='lazy'/>
    //   <h1 className='image-name'>{first_name + " " + middle_name + " " + last_name}</h1>
    //   <h1 className='image-course'>{course}</h1>
    //   <h1 className='image-issue-date'>{issue_date}</h1>
    //   <h1 className='image-id'>{certificate_number}</h1>
    // </div>
    // </div>
    <div>
      {imageSrc && (
        <canvas ref={canvasRef} style={{ display: 'block' }}></canvas>
      )}
    </div>
  )
}
