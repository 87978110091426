import React from "react";
import SithLight from "../../images/SITH-LOGO-WHITE.png";
import SithDark from "../../images/SITH-LOGO-BLACK.png";
import Favicon from "../../images/SITH-LOGO.png";
import '../../App.css';
import {Link} from "react-router-dom";

const Logo = () => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/add-certificate`} className="logo-link">
      <img className="logo-light logo-img  my-logo" src={SithLight} alt="logo" />
      <img className="logo-dark logo-img my-logo" src={SithDark} alt="logo" />
      <img className="logo-small logo-img logo-img-small " src={Favicon} alt="logo" />
    </Link>
  );
};

export default Logo;