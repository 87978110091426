import React, { useState } from "react";
// import SithLogo from "./images/SITH-LOGO-BLACK.png";
import SITHLOGOWHITE from "../images/SITH_LOGO_WHITE.png";
import SITHLOGOBLACK from "../images/SITH_LOGO_BLACK.png";
import Head from "../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
    Block,
    Icon,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Button,
    PreviewCard
} from "../components/Component";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "./auth";
import { admin_login } from "../utils/constants";

import { toast, ToastContainer } from 'react-toastify';

const Login = () => {
    const [passState, setPassState] = useState(false);


    const auth = useAuth();
    const navigate = useNavigate();

    const onFormSubmit = (form) => {
        const { email, passcode } = form;
        let submittedData = {
            email: email,
            password: passcode
        };
        axios.post(admin_login, submittedData)
            .then(res => {
                if (!res.data.data) {
                    toast.error(res.data.message);
                }
                else {
                    toast.success(res.data.message);
                    setTimeout(() => {
                        auth.login(res.data.data);
                        navigate('/add-certificate', { replace: true });
                    }, 1000);
                }
            })
            // .catch(err => {
            //     toast.error(err.response.data.message);
            // })
    };

    const { register, handleSubmit, formState: { errors } } = useForm();

    return (
        <React.Fragment>
            <Head title="Admin Login" />
            <ToastContainer position="bottom-right" hideProgressBar="true" />
            <Block className="nk-block-middle nk-auth-body mt-5 pt-5 wide-xs " >
                <div className="brand-logo pb-4 text-center">
                    <Link className="logo-link" to={`${process.env.PUBLIC_URL}/admin`}>
                        <img className="logo-light logo-img logo-img-lg" src={SITHLOGOWHITE} alt="logo" />
                        <img className="logo-dark logo-img logo-img-lg" src={SITHLOGOBLACK} alt="logo-dark" />
                    </Link>
                </div>

                <PreviewCard className="card-bordered mt-4" bodyClass="card-inner-lg">
                    <BlockHead>
                        <BlockContent>
                            <BlockTitle tag="h4">Sign-In</BlockTitle>
                            <BlockDes>
                                <p>Please enter your Email Id and Password.</p>
                            </BlockDes>
                        </BlockContent>
                    </BlockHead>
                    <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} autoComplete="on">
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="default-01">
                                    Email
                                </label>
                            </div>
                            <div className="form-control-wrap">
                                <input
                                    type="text"
                                    id="default-01"
                                    {...register('email', { required: "This field is required" })}
                                    placeholder="Enter your Email Id"
                                    className="form-control-lg form-control" />
                                {errors.email && <span className="invalid">{errors.email.message}</span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="password">
                                    Password
                                </label>
                            </div>
                            <div className="form-control-wrap">
                                <a
                                    href="#password"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        setPassState(!passState);
                                    }}
                                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                                >
                                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                                </a>
                                <input
                                    type={passState ? "text" : "password"}
                                    id="password"
                                    {...register('passcode', { required: "This field is required" })}
                                    placeholder="Enter your password"
                                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`} />
                                {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <Button size="lg" className="btn-block" type="submit" color="primary">
                                Sign in
                            </Button>
                        </div>
                    </Form>
                </PreviewCard>
            </Block>
            <AuthFooter />
        </React.Fragment >
    )
};
export default Login;
