
import { useEffect } from 'react';
import './App.css';
import { AuthProvider } from './Auth/auth';
import ThemeProvider from "./layout/provider/Theme";
import Router from './routes/Router';
function App() {
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault(); // Prevent the default right-click behavior
    };

    // Add event listener when the component mounts
    document.body.addEventListener('contextmenu', handleContextMenu);

    // Clean up the event listener when the component unmounts
    return () => {
      document.body.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;